@tailwind base;
@tailwind components;
@tailwind utilities;

/* fonts */

@font-face {
  font-family: Lato;
  src: url("./styles/fonts/Lato/Lato-Regular.ttf") format("opentype");
}
*{
  scroll-behavior: smooth;
}
body {
  font-family: Lato, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    background: #ffffff;
    scroll-behavior: smooth;
}
input,select{
  outline: none;
}
.batch_heart{
  background: tomato;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  position: absolute;
  right: -24%;
  top: -9%;
  cursor: pointer;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.product_card {
  width: 14.38rem !important;
}

@keyframes blink {
  0%, 50% { opacity: 1; }
  50.1%, 100% { opacity: 0; }
}
.animate-blink {
  animation: blink 1s step-start infinite;
}

/* Oculta o hace muy sutil la scrollbar */
.scroll-thin::-webkit-scrollbar {
  height: 4px;
}

.scroll-thin::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 9999px;
}

.scroll-thin::-webkit-scrollbar-track {
  background: transparent;
}

.scroll-thin {
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: rgba(0, 0, 0, 0.1) transparent; /* Firefox */
}
